import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import React, { useState, useLayoutEffect, useRef, useEffect } from "react"
import Logo from "../images/logo"
import TargetIcon from "../images/target-icon"
import CallToActionQualify from "../call-to-action-qualify/call-to-action-qualify"
import ModalMenuIcon from "../images/modal-menu-icon"
import "./header.css"
//import { window, document } from "browser-monads"

import MenuItems from "./header-menu-items"

//const Banner = () => {
//  const { isVisible, message, link, hideBanner } = useBannerContext()

//  return (
//    isVisible && (
//      <div className="cta flex w-full py-2 px-5 text-center text-white md:px-6">
//        <a href={link} className="w-full hover:opacity-75">
//          {message}
//        </a>
//        <button
//          className="outline-none focus:outline-none"
//          onClick={hideBanner}
//        >
//          <TargetIcon rotate />
//        </button>
//      </div>
//    )
//  )
//}


function Header({ animate = false }) {
  const [isExpanded, toggleExpansion] = useState(false)
  const [showNav, setShowNav] = useState(false)
  
  useEffect(() => {
    const windowHeight = window.innerHeight
    const onScroll = () => {
      const { y } = document.documentElement.getBoundingClientRect()
      const top = Math.abs(y)

      if (top < windowHeight / 4) {
        setShowNav(false)
      } else if (top >= windowHeight && !showNav.current) {
        setShowNav(true)
      }
    }

    if (animate) {
      window.addEventListener("scroll", onScroll)
    }
    return () => {
      if (animate) {
        window.removeEventListener("scroll", onScroll)
      }
    }
  }, [])

  const styles = {
      border: '2px solid white ', 
      padding: "4px 10px",
    };

  return (
    <>
      <div className="fixed top-0 z-20 w-full">
          <nav className={`flex flex-wrap justify-between py-2 lg:py-0 md:px-12 w-100 fixed md:top-0 z-20 border-b md:border-transparent text-white`}>
              <MenuItems></MenuItems>
          </nav>
          <nav
              className={`${showNav ? "slide-up" : null} ${
                animate ? "nav-hide" : null
              } dark-green-nav flex flex-wrap justify-between py-2 lg:py-0 md:px-12 w-100 fixed md:top-0 z-20 border-b md:border-transparent text-white`}
            >
              <MenuItems></MenuItems>
          </nav>
      </div>

      <div className="fixed z-20">
        <nav className={`fixed md:right-0 md:px-12 text-white`}>
          
          {/*
          <div
            className={`${
              isExpanded ? `block` : `hidden`
            } w-full block flex-grow md:flex flex md:items-center md:w-auto font-light tracking-wide`}
          >
            <div className="py-10 font-light text-2xl">
              <Link
                activeClassName="nav-active"
                to="/design-builder"
                className="px-5 py-5 mx-8 md:mx-0 md:py-0 text-white hover:text-gray-200 block md:inline-block border-t border-gray-500 md:border-none"
              >
                <div style={styles}>
                  Get Quote
                </div>
              </Link>
            </div>
          </div>

          */}

        </nav>
      </div>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
