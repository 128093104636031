import React, { useLayoutEffect, useRef, useState, useEffect } from "react"

import { Link, graphql } from "gatsby"
import Image from "gatsby-image"

import { useScrollPercentage } from "../../hooks/useScrollPercentage"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HomeProductPreview from "../../components/home/home-product-preview"
import HomeVideoExpand from "../../components/home/home-video-expand"
import HomeHero from "../../components/home/home-hero"
//import CallToActionQualify from "../../components/call-to-action-qualify/call-to-action-qualify"
import FaqSection from "../../components/faq-section"
import StarIcons from "../../components/images/star-icons"
import HomeCarousel from "../../components/home/home-carousel"
import HomeLoaderLottie from "../../components/home/home-loader-lottie"
// import GIF from "./../images/modal-exploded.gif"
import StickyMobileCta from "../../components/call-to-action-qualify/sticky-mobile-cta"
import PhoneSection from "../../components/phone-section/phone-section"
import MobilePhoneSection from "../../components/phone-section/mobile-phone-section"
import Helmet from "react-helmet"

import Logo from "../../components/images/logo"
import ScrollDownButtonHome from "../../components/scroll-down-button-home"
import CallToActionQualify from "../../components/call-to-action-qualify/call-to-action-qualify"
import CallToActionCalculate from "../../components/call-to-action-calculate/call-to-action-calculate"

import { usePlacesWidget } from "react-google-autocomplete";
import "./style.css"
import ReactDOM from 'react-dom';

import LoadingSpinner from "../../components/loading-spinner"

export default function Bot2() {



  return (
    <>

      <iframe src="https://dev3.checksolarcost.com/bot" title=""></iframe>

    </>
  )
}